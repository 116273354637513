//------------------------------------A C T I O N S----------------------------------------------------
// @flow
import { HomeActionTypes } from './constants';

type AuthAction = { type: string, payload: {} | string };

// common success
export const getHomeActions = (data): AuthAction => ({
    type: HomeActionTypes.HOME_DATA_FIRST,
    data
});

export const getBrandStoryActions = (data): AuthAction => ({
    type: HomeActionTypes.BRAND_STORY_FIRST,
    data
});
export const getLogoActions = (data): AuthAction => ({
    type: HomeActionTypes.LOGO_FIRST,
    data
});
export const getInstaVideoActions = (data): AuthAction => ({
    type: HomeActionTypes.INSTA_VIDEO_FIRST,
    data
});
export const getInstaImageActions = (data): AuthAction => ({
    type: HomeActionTypes.INSTA_IMAGE_FIRST,
    data
});

export const getCollectionActions = (data): AuthAction => ({
    type: HomeActionTypes.COLLECTION_DATA_FIRST,
    data
});

export const getParticularCollectionActions = (data): AuthAction => ({
    type: HomeActionTypes.PARTICULAR_COLLECTION_DATA_FIRST,
    data
});
