//------------------------------------S A G A---------------------------------------------------------------
import { all, fork, put, takeEvery, call, takeLatest } from 'redux-saga/effects';
import { ProductActionTypes } from './constants';

import { addToCart, cartDetailData, categoryData, deleteToCart, proceedToBuy, productData, productDetailData, ordersData } from './api';
/**
 * Login the user
 * @param {*} payload - username and password
 */


function* getProductFunction(data) {
    try {
        yield put({
            type: ProductActionTypes.GET_PRODUCT_LOADING,
            payload: {},
        });
        const response = yield call(productData, data);
        // console.log(response,'api response')
        if (response?.status === 200) {
            yield put({
                type: ProductActionTypes.GET_PRODUCT_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: ProductActionTypes.GET_PRODUCT_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: ProductActionTypes.GET_PRODUCT_ERROR,
            payload: error,
        });
    }
}

function* getOrdersFunction(data) {
    try {
        yield put({
            type: ProductActionTypes.GET_USER_ORDERS_LOADING,
            payload: {},
        });
        const response = yield call(ordersData, data);
        // console.log(response,'api response')
        if (response?.status === 200) {
            yield put({
                type: ProductActionTypes.GET_USER_ORDERS_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: ProductActionTypes.GET_USER_ORDERS_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: ProductActionTypes.GET_USER_ORDERS_ERROR,
            payload: error,
        });
    }
}

function* getCategoryFunction(data) {
    try {
        yield put({
            type: ProductActionTypes.GET_CATEGORY_LOADING,
            payload: {},
        });
        const response = yield call(categoryData, data);
        // console.log(response,'api response')
        if (response?.status === 200) {
            yield put({
                type: ProductActionTypes.GET_CATEGORY_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: ProductActionTypes.GET_CATEGORY_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: ProductActionTypes.GET_CATEGORY_ERROR,
            payload: error,
        });
    }
}
function* getProductDetailFunction(data) {
    try {
        yield put({
            type: ProductActionTypes.GET_PRODUCT_DETAIL_LOADING,
            payload: {},
        });
        const response = yield call(productDetailData, data);
        // console.log(response,'api response')
        if (response?.status === 200) {
            yield put({
                type: ProductActionTypes.GET_PRODUCT_DETAIL_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: ProductActionTypes.GET_PRODUCT_DETAIL_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: ProductActionTypes.GET_PRODUCT_DETAIL_ERROR,
            payload: error,
        });
    }
}
function* getCartDetailFunction(data) {
    try {
        yield put({
            type: ProductActionTypes.GET_CART_DETAIL_LOADING,
            payload: {},
        });
        const response = yield call(cartDetailData, data);
        // console.log(response,'api response')
        if (response?.status === 200) {
            yield put({
                type: ProductActionTypes.GET_CART_DETAIL_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: ProductActionTypes.GET_CART_DETAIL_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: ProductActionTypes.GET_CART_DETAIL_ERROR,
            payload: error,
        });
    }
}

function* addToCartFunction(data) {
    try {
        yield put({
            type: ProductActionTypes.ADDTOCART_LOADING,
            payload: {},
        });
        const response = yield call(addToCart, data);
        if (response.data.status) {
            yield put({
                type: ProductActionTypes.ADDTOCART_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: ProductActionTypes.ADDTOCART_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: ProductActionTypes.ADDTOCART_ERROR,
            payload: error,
        });
    }
}

function* proceedToBuyFunction(data) {
    try {
        yield put({
            type: ProductActionTypes.PROCEED_TO_BUY_LOADING,
            payload: {},
        });
        const response = yield call(proceedToBuy, data);
        const ProductID = response?.data?.response
        const product = ProductID?.map((item) => item?.productId)
        localStorage.setItem('productId', JSON.stringify(product));

        if (response.data.status) {
            yield put({
                type: ProductActionTypes.PROCEED_TO_BUY_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: ProductActionTypes.PROCEED_TO_BUY_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: ProductActionTypes.PROCEED_TO_BUY_ERROR,
            payload: error,
        });
    }
}
function* deleteToCartFunction(data) {
    try {
        yield put({
            type: ProductActionTypes.DELETETOCART_LOADING,
            payload: {},
        });
        const response = yield call(deleteToCart, data);
        if (response.data.status) {
            yield put({
                type: ProductActionTypes.DELETETOCART_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: ProductActionTypes.DELETETOCART_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: ProductActionTypes.DELETETOCART_ERROR,
            payload: error,
        });
    }
}

function* getProductDetailsFunction(data) {
    try {
        yield put({
            type: ProductActionTypes.GET_PRODUCT_DETAILS_LOADING,
            payload: {},
        });
        const response = yield call(productDetailData, data);
        // console.log(response,'api response')
        if (response?.status === 200) {
            yield put({
                type: ProductActionTypes.GET_PRODUCT_DETAILS_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: ProductActionTypes.GET_PRODUCT_DETAILS_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: ProductActionTypes.GET_PRODUCT_DETAILS_ERROR,
            payload: error,
        });
    }
}


function* StateEmptyFunction() {
    try {
        yield put({
            type: ProductActionTypes.STATE_EMPTY_SUCCESS,
            payload: {},
        });

    } catch (error) {

    }
}


export function* ProductData() {
    yield takeEvery(ProductActionTypes.GET_PRODUCT_FIRST, getProductFunction);
    yield takeEvery(ProductActionTypes.GET_USER_ORDERS_FIRST, getOrdersFunction);

}
export function* CategoryData() {
    yield takeEvery(ProductActionTypes.GET_CATEGORY_FIRST, getCategoryFunction);

}
export function* ProductDetailData() {
    yield takeEvery(ProductActionTypes.GET_PRODUCT_DETAIL_FIRST, getProductDetailFunction);

}
export function* CartDetailData() {
    yield takeEvery(ProductActionTypes.GET_CART_DETAIL_FIRST, getCartDetailFunction);

}
export function* AddtocartData() {
    yield takeEvery(ProductActionTypes.ADDTOCART_FIRST, addToCartFunction);
    yield takeEvery(ProductActionTypes.PROCEED_TO_BUY_FIRST, proceedToBuyFunction);

}
export function* DeletetocartData() {
    yield takeEvery(ProductActionTypes.DELETETOCART_FIRST, deleteToCartFunction);

}

export function* ProductStateEmpty() {
    yield takeEvery(ProductActionTypes.STATE_EMPTY_FIRST, StateEmptyFunction);
}

export function* ProductDetailsData() {
    yield takeEvery(ProductActionTypes.GET_PRODUCT_DETAILS_FIRST, getProductDetailsFunction);
}

function* productSaga() {
    yield all([
        fork(ProductData),
        fork(CategoryData),
        fork(ProductDetailData),
        fork(CartDetailData),
        fork(AddtocartData),
        fork(DeletetocartData),
        fork(ProductStateEmpty),
    ]);
}
export default productSaga;


