import React from 'react';
import { footerlogo, map ,instra } from '../../assets/img';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className=" text-center text-light jost">
            <div className="bg_colour">
                <div className="container-fluid px-md-5 px-1">
                    <div className="row  mx-auto d-flex">
                        {/* Desktop View */}
                        <div className="col-md-6 col-lg-3 ps-md-5 text-start d-none d-md-block text-white py-4">
                            <img src={footerlogo} className=" text-white mb-3" style={{width:"138px"}} alt="Footer Logo" />
                            <p className="m-0 custom_Font_Renner" style={{fontSize:"18px", lineHeight:"24px"}}>India's Premier European</p>
                            <p className="m-0 custom_Font_Renner" style={{fontSize:"18px", lineHeight:"24px"}}>style bean-to-bar</p>
                            <p className="custom_Font_Renner" style={{fontSize:"18px", lineHeight:"24px"}}>chocolate experience.</p>
                        </div>

                        {/* Mobile View */}
                        <div className="col-12 d-sm-block text-start text-white d-md-none">
                            <div className="d-flex align-items-center">
                                <div>
                                    <img src={footerlogo} className="w-75 text-white " alt="Footer Logo" />
                                </div>
                                <div className="">
                                    <p className="m-0 custom_Font_Renner">India's Premier European style bean-to-bar chocolate experience.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-4 text-start text-white py-4 d-md-block d-none">
                            <p className="mb-1  custom_Font_Renner fw-bold" style={{fontSize:"20px", lineHeight:"34px"}} >Get in touch</p>
                            <a className="d-flex text-decoration-none text-white" href="tel:0712-4045414">
                                <i className="bi bi-telephone-fill fs-5"></i>
                                <p className="ms-3 custom_Font_Renner" style={{fontSize:"18px", lineHeight:"34px"}}>0712-4045414</p>
                            </a>
                            <a className="d-flex text-decoration-none text-white" href="https://www.google.com/maps/place/Mozimo+Chocolates/@30.7460256,76.7872281,2977m/data=!3m1!1e3!4m6!3m5!1s0x390fedde1e357d9d:0x545b13b725f8c7c8!8m2!3d30.7472085!4d76.7933329!16s%2Fg%2F11v11wkp0w?hl=en&entry=ttu&g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D">
                            <img src={map}  className=" text-white " alt="location" style={{width:"5%",height:"6%"}} />
                                <p className="ms-2 custom_Font_Renner mb-0 " style={{fontSize:"18px", lineHeight:"22px"}}>SCO 8, Inner Market, 9-D, Sector 9,<br/> Chandigarh, 160009</p>
                            </a>
                            <p className='mt-3 mb-0'>
                                <Link to={"/location"} className=' text-decoration-none custom_Font_Renner  text-white ' style={{fontSize:"18px", lineHeight:"35px"}}>Locate our store</Link>
                            </p>
                            <p>
                                <Link to={"/bulk"} className='text-decoration-none custom_Font_Renner  text-white' style={{fontSize:"18px", lineHeight:"30px"}}>Bulk Ordering</Link>
                            </p>
                        </div>
                        <div className="col-6 col-lg-4 text-start text-white py-4 d-md-none d-block">
                            <h3 className="mb-4 fw-bold custom_Font_Renner" style={{fontSize:"17px" ,lineHeight:"17.77px",fontWeight:"500" }} >Get in touch</h3>
                            <a className="d-flex text-decoration-none text-white" href="tel:0712-4045414">
                                <i className="bi bi-telephone-fill " style={{width:"10px"}}></i>
                                <p className="ms-3 mb-2 custom_Font_Renner" style={{fontSize:"16px", lineHeight:"34px"}}>0712-4045414</p>
                            </a>
                            <a className="d-flex text-decoration-none text-white" href="https://www.google.com/maps/place/Mozimo+Chocolates/@30.7460256,76.7872281,2977m/data=!3m1!1e3!4m6!3m5!1s0x390fedde1e357d9d:0x545b13b725f8c7c8!8m2!3d30.7472085!4d76.7933329!16s%2Fg%2F11v11wkp0w?hl=en&entry=ttu&g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D">
                            <img src={map}  className=" text-white " alt="location" style={{width:"10%",height:"10%"}} />
                                <p className="ms-2 mb-2 custom_Font_Renner mb-0 " style={{fontSize:"16px", lineHeight:"18px"}}>SCO 8, Inner Market, 9-D, Sector 9,<br/> Chandigarh, 160009</p>
                            </a>
                            <p className='mt-3 mb-0'>
                                <Link to={"/location"} className=' text-decoration-none custom_Font_Renner  text-white ' style={{fontSize:"16px", lineHeight:"18px"}}>Locate our store</Link>
                            </p>
                            <p>
                                <Link to={"/bulk"} className='text-decoration-none custom_Font_Renner  text-white'style={{fontSize:"16px", lineHeight:"18px"}}>Bulk Ordering</Link>
                            </p>
                        </div>

                        {/* Know More */}
                        <div className="col-6 col-lg-3 text-start text-white py-4 d-md-block d-none">
                            <p className="mb-1 fw-bold custom_Font_Renner"  style={{fontSize:"20px", lineHeight:"34px"}} >Know more</p>
                            <Link className='custom_Font_Renner text-decoration-none text-white' style={{fontSize:"18px", lineHeight:"37px"}} to={"privacy-policy"} >Privacy Policy</Link><br />
                            <Link className='custom_Font_Renner text-decoration-none text-white'style={{fontSize:"18px", lineHeight:"37px"}}  to={"return-policy"}>Refund & Return Policy</Link><br />
                            <Link className="mt-1   custom_Font_Renner text-decoration-none text-white" style={{fontSize:"18px", lineHeight:"37px"}}  to={"terms-and-conditions"}>Terms & Conditions</Link><br />
                            <Link className="mt-1 custom_Font_Renner text-decoration-none text-white" style={{fontSize:"18px", lineHeight:"37px"}}  to={"shipping-policy"}>Shipping Policy</Link><br />
                            <Link className="mt-1 custom_Font_Renner text-decoration-none d-md-none fs-5 ">
                                <a href="/faq" className="text-decoration-none fw-bold text-white">FAQ</a>
                            </Link>
                        </div>
                        <div className="col-6 col-lg-3 text-start text-white py-4 d-md-none d-block">
                            <h3 className="mb-4 fw-bold custom_Font_Renner text-decoration-none text-white" style={{fontSize:"17px" ,lineHeight:"17.77px",fontWeight:"500" }}  >Know more</h3>
                            <Link className='custom_Font_Renner text-decoration-none text-white mb-2' style={{fontSize:"16px", lineHeight:"18px"}}  to={"privacy-policy"} >Privacy Policy</Link><br />
                            <Link className='custom_Font_Renner text-decoration-none text-white mb-2'style={{fontSize:"16px", lineHeight:"18px"}}  to={"return-policy"}>Refund & Return Policy</Link><br />
                            <Link className="  custom_Font_Renner text-decoration-none text-white  mb-2" style={{fontSize:"16px", lineHeight:"18px"}}  to={"terms-and-conditions"}>Terms & Conditions</Link><br />
                            <Link className=" custom_Font_Renner text-decoration-none text-white  mb-2" style={{fontSize:"16px", lineHeight:"18px"}}  to={"shipping-policy"}>Shipping Policy</Link><br />
                            <Link className=" custom_Font_Renner d-md-none text-decoration-none fs-5" to={"faq"} >
                                <a className="text-decoration-none fw-bold text-white" style={{fontSize:"16px", lineHeight:"18px"}} >FAQ</a>
                            </Link>
                        </div>

                        {/* Social Media Links - Desktop */}
                        <div className="col-6 col-lg-2 d-none d-md-block text-start text-white py-4">
                            <p className=" custom_Font_Renner fw-bold mb-1"  style={{fontSize:"20px", lineHeight:"34px"}}>Find Us On</p>
                            <div className="mb-3">
                                <a href="https://www.facebook.com/mozimo.choc/">
                                    <i className="bi bi-facebook text-white fs-4"></i>
                                </a>
                                <a href="https://www.instagram.com/mozimo.choc" className="ms-4 ">
                                <img width="30" height="30" className='mb-2' src={instra} alt="instagram-new"/>

                                </a>
                            </div>
                            <div className='mb-3'>
                                <a href="https://youtube.com/@mozimochocolates">
                                    <i className="bi bi-youtube fs-4 text-white"></i>
                                </a>
                                <a href="https://www.linkedin.com/company/mozimochocolates/" className="ms-4">
                                    <i className="bi bi-linkedin fs-4 text-white"></i>
                                </a>
                            </div>
                            <p className="mt-4 fw-light fs-5">
                                <a href="/faq" className="text-decoration-none fw-bold text-white">FAQ</a>
                            </p>
                        </div>

                        {/* Social Media Links - Mobile */}
                        <div className="col-12 col-lg-3 d-md-none text-start text-white">
                            <h4 className="mb-3 custom_Font_Renner fw-bold" style={{fontSize:"17px" ,lineHeight:"17.77px",fontWeight:"500" }} >Find Us On</h4>
                            <a href="https://www.facebook.com/mozimo.choc/">
                                <i className="bi bi-facebook text-white fs-5"></i>
                            </a>
                            <a href="https://www.instagram.com/mozimo.choc" className="ms-4">
                            <img width="28" height="28" className='mb-2' src={instra} alt="instagram-new"/>
                            </a>
                            <a href="https://youtube.com/@mozimochocolates" className="ms-4">
                                <i className="bi bi-youtube fs-5 text-white"></i>
                            </a>
                            <a href="https://www.linkedin.com/company/mozimochocolates/" className="ms-4">
                                <i className="bi bi-linkedin fs-5 text-white"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-center bottom_margen pb-md-0 pb-3 justify-content-center bg_colour2 custom_Font_Renner py-1'>

                <div className=' d-flex text-center justify-content-center'>
                    <p className='mb-0 mx-2' > Powered By <Link className='text-decoration-none border-bottom border-1' target='#' style={{ color: "#f9a004 " }} to="https://www.rowthtech.com/" >  RowthTech</Link></p>
                    <p className='mb-0'>© 2024 Mozimo | All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
