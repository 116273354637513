//------------------------------------S A G A---------------------------------------------------------------
import { all, fork, put, takeEvery, call, takeLatest } from 'redux-saga/effects';
import { HomeActionTypes } from './constants';

import { beandStoryData,logoData, homePageData, instaImageData, instaVideoData, collectionData, particularCollectionData } from './api';
/**
 * Login the user
 * @param {*} payload - username and password
 */


function* homeDataGetFunction(data) {
    try {
        yield put({
            type: HomeActionTypes.HOME_DATA_LOADING,
            payload: {},
        });
        const response = yield call(homePageData, data);
        if (response?.status === 200) {
            yield put({
                type: HomeActionTypes.HOME_DATA_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: HomeActionTypes.HOME_DATA_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: HomeActionTypes.HOME_DATA_ERROR,
            payload: error,
        });
    }
}

function* getBrandStoryFunction(data) {
    try {
        yield put({
            type: HomeActionTypes.BRAND_STORY_LOADING,
            payload: {},
        });
        const response = yield call(beandStoryData, data);
        if (response?.status === 200) {
            yield put({
                type: HomeActionTypes.BRAND_STORY_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: HomeActionTypes.BRAND_STORY_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: HomeActionTypes.BRAND_STORY_ERROR,
            payload: error,
        });
    }
}
function* getLogoFunction(data) {
    try {
        yield put({
            type: HomeActionTypes.LOGO_LOADING,
            payload: {},
        });
        const response = yield call(logoData, data);
        if (response?.status === 200) {
            yield put({
                type: HomeActionTypes.LOGO_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: HomeActionTypes.LOGO_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: HomeActionTypes.LOGO_ERROR,
            payload: error,
        });
    }
}

function* getInstaVideoFunction(data) {
    try {
        yield put({
            type: HomeActionTypes.INSTA_VIDEO_LOADING,
            payload: {},
        });
        const response = yield call(instaVideoData, data);
        if (response?.status === 200) {
            yield put({
                type: HomeActionTypes.INSTA_VIDEO_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: HomeActionTypes.INSTA_VIDEO_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: HomeActionTypes.INSTA_VIDEO_ERROR,
            payload: error,
        });
    }
}

function* getInstaImageFunction(data) {
    try {
        yield put({
            type: HomeActionTypes.INSTA_IMAGE_LOADING,
            payload: {},
        });
        const response = yield call(instaImageData, data);
        if (response?.status === 200) {
            yield put({
                type: HomeActionTypes.INSTA_IMAGE_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: HomeActionTypes.INSTA_IMAGE_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: HomeActionTypes.INSTA_IMAGE_ERROR,
            payload: error,
        });
    }
}

function* getCollectionFunction(data) {
    try {
        yield put({
            type: HomeActionTypes.COLLECTION_DATA_LOADING,
            payload: {},
        });
        const response = yield call(collectionData, data);
        // console.log(response, 'api response')
        if (response?.status === 200) {
            yield put({
                type: HomeActionTypes.COLLECTION_DATA_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: HomeActionTypes.COLLECTION_DATA_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: HomeActionTypes.COLLECTION_DATA_ERROR,
            payload: error,
        });
    }
}

function* getParticularCollectionFunction(data) {
    try {
        yield put({
            type: HomeActionTypes.PARTICULAR_COLLECTION_DATA_LOADING,
            payload: {},
        });
        const response = yield call(particularCollectionData, data);
        // console.log(response, 'api response')
        if (response?.status === 200) {
            yield put({
                type: HomeActionTypes.PARTICULAR_COLLECTION_DATA_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: HomeActionTypes.PARTICULAR_COLLECTION_DATA_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: HomeActionTypes.PARTICULAR_COLLECTION_DATA_ERROR,
            payload: error,
        });
    }
}

export function* homeDataGet() {
    yield takeEvery(HomeActionTypes.HOME_DATA_FIRST, homeDataGetFunction);
    yield takeEvery(HomeActionTypes.COLLECTION_DATA_FIRST, getCollectionFunction);
    yield takeEvery(HomeActionTypes.PARTICULAR_COLLECTION_DATA_FIRST, getParticularCollectionFunction);
    yield takeEvery(HomeActionTypes.BRAND_STORY_FIRST, getBrandStoryFunction);
    yield takeEvery(HomeActionTypes.INSTA_VIDEO_FIRST, getInstaVideoFunction);
    yield takeEvery(HomeActionTypes.INSTA_IMAGE_FIRST, getInstaImageFunction);
    yield takeEvery(HomeActionTypes.LOGO_FIRST, getLogoFunction);
}
function* homeSaga() {
    yield all([
        fork(homeDataGet),
    ]);
}
export default homeSaga;


