import { all, fork, put, takeEvery, call, takeLatest } from 'redux-saga/effects';
import { addAddressData, addEnquaryData, createPaymentOrder, verifyPayment } from './api';
import { addAddressTypes } from './constants';
/**
 * Login the user
 * @param {*} payload - username and password
 */

function* addAddressFunction(data) {
    try {
        yield put({
            type: addAddressTypes.ADD_ADDRESS_LOADING,
            payload: {},
        });
        const response = yield call(addAddressData, data);
        // console.log({ response })
        if (response.data.status) {
            yield put({
                type: addAddressTypes.ADD_ADDRESS_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: addAddressTypes.ADD_ADDRESS_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: addAddressTypes.ADD_ADDRESS_ERROR,
            payload: error,
        });
    }
};
function* addEnquaryFunction(data) {
    try {
        yield put({
            type: addAddressTypes.ADD_ENQUARY_LOADING,
            payload: {},
        });
        const response = yield call(addEnquaryData, data);
        // console.log({ response })
        if (response.data.status) {
            yield put({
                type: addAddressTypes.ADD_ENQUARY_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: addAddressTypes.ADD_ENQUARY_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: addAddressTypes.ADD_ENQUARY_ERROR,
            payload: error,
        });
    }
};


function* createOrderSaga(data) {
    try {
        yield put({
            type: addAddressTypes.PAYMENT_LOADING,
            payload: {},
        });
        const response = yield call(createPaymentOrder, data);
        console.log({ response }, 'order')
        if (response.data.status) {
            yield put({
                type: addAddressTypes.PAYMENT_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: addAddressTypes.PAYMENT_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: addAddressTypes.PAYMENT_ERROR,
            payload: error,
        });
    }
};


function* verifyPaymentSaga(data) {
    try {
        yield put({
            type: addAddressTypes.VERIFY_PAYMENT_LOADING,
            payload: {},
        });
        const response = yield call(verifyPayment, data);
        console.log({ response }, 'verify payment')
        if (response.data.status) {
            yield put({
                type: addAddressTypes.VERIFY_PAYMENT_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            console.log({ response }, 'error verify payment')
            yield put({
                type: addAddressTypes.VERIFY_PAYMENT_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: addAddressTypes.VERIFY_PAYMENT_ERROR,
            payload: error,
        });
    }
};


function* StateEmptyFunction() {
    try {
        yield put({
            type: addAddressTypes.STATE_EMPTY_SUCCESS,
            payload: {},
        });

    } catch (error) {

    }
}

export function* paymentFunction() {
    yield takeLatest(addAddressTypes.INITIATE_PAYMENT, createOrderSaga);
    yield takeLatest(addAddressTypes.VERIFY_PAYMENT_FIRST, verifyPaymentSaga);
    yield takeLatest(addAddressTypes.STATE_EMPTY_FIRST, StateEmptyFunction);
}



export function* addAddressDataSaga() {
    yield takeEvery(addAddressTypes.ADD_ADDRESS_FIRST, addAddressFunction);

}
export function* addEnquaryDataSaga() {
    yield takeEvery(addAddressTypes.ADD_ENQUARY_FIRST, addEnquaryFunction);

}



function* paymentSaga() {
    yield all([

        fork(addAddressDataSaga),
        fork(paymentFunction),
        fork(addEnquaryDataSaga),
    ]);
}
export default paymentSaga;