import React, { useState } from "react";
import {
  Accordion,
  Dropdown,
  DropdownButton,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  NavLink,
  Offcanvas,
} from "react-bootstrap";
import {
  ChocalateIconSmallNavbar,
  GiftingSmallNavbar,
  HomeIconSmallNavbar,
  LocationIconSmallNavbar,
  MenuIconSmallNavbar,
} from "../../assets/img";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserFromSession } from "../../helpers/api/apiCore";
import {
  addToCart,
  getCategoryActions,
  stateEmpty,
} from "../../../redux/actions";

const FooterNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { selectedProduct } = location?.state || {};
  const user = getUserFromSession();
  const [quantityTotal, setQuantityTotal] = useState(1);
  const userID = user?._id || null;
  const collection = useSelector(
    (state) => state?.collectionDataReducer?.homeData?.response || []
  );
  const [showChocolates, setShowChocolates] = useState(true);
  const [showCollection, setShowCollection] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const basePriceTotal = 800;
  const subTotal = basePriceTotal * quantityTotal;

  const increaseQuantityEnd = () => {
    setQuantityTotal((prevQuantity) => prevQuantity + 1);
  };


  const decreaseQuantityEnd = () => {
    if (quantityTotal > 1) {
      setQuantityTotal((prevQuantity) => prevQuantity - 1);
    }
  };

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleCloseOffcanvas = () => setShow(false);
  


  const shopCategory = useSelector(
    (state) => state.categoryDataReducer?.categoryData?.respone
  );
  const GoingHandle = () => {
    handleCloseOffcanvas(false);
  };

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };
  const chocolateHandle = () => {
    setShowChocolates(!showChocolates);
  };
  const collectionHandle = () => {
    setShowCollection(!showCollection);
  };
  const Morehandle = () => {
    setShowMore(!showMore);
  };
  const handleShopMenu = () => {
    setIsVisible(!isVisible);
  };
  const isProductDetailsPage = location.pathname === "/product-details";
  return (
    <>
      {!isProductDetailsPage ? (
        <Navbar
          fixed="bottom"
          bg="light"
          className="justify-content-around box_shadow py-3"
        >
          <Nav className="w-100  custom_Font_Renner d-flex justify-content-around">
            <Link to="/" className="text-center nav-link">
              <img src={HomeIconSmallNavbar} className="w-50" alt="" />
              <div className="nav_font_mobile">Home</div>
            </Link>
            <Link to="/bulk" className="text-center nav-link">
              <img src={GiftingSmallNavbar} className="w-50" alt="" />
              <div className="nav_font_mobile">Gifting</div>
            </Link>
            <Link onClick={handleShow} className="text-center nav-link">
              <img src={MenuIconSmallNavbar} className="w-50" alt="" />
              <div className="nav_font_mobile">Menu</div>
            </Link>
            <Link onClick={handleShopMenu} className="text-center nav-link">
              <img src={ChocalateIconSmallNavbar} className="w-50 " alt="" />
              <div className="nav_font_mobile">Shop</div>
            </Link>
            <Link to="/location" className="text-center nav-link">
              <img src={LocationIconSmallNavbar} className="w-50" alt="" />
              <div className="nav_font_mobile">Store</div>
            </Link>
          </Nav>
        </Navbar>
      ) : (
        <div className="bg-white p-2 fixed-bottom">
          <div className="d-flex justify-content-center align-items-center m-0 d-md-none">
            <div
              className="bg_colour rounded-3 add_shadow btn-group"
              role="group"
              aria-label="Basic outlined example"
            >
              {quantityTotal === 1 ? (
                <button className="btn px-3 py-0 border-end rounded-0 text-white fw-bold">
                  -
                </button>
              ) : (
                <button
                  className="btn px-3 py-0 border-end rounded-0 text-white fw-bold"
                  onClick={decreaseQuantityEnd}
                >
                  -
                </button>
              )}
              <span className="px-4 py-1 text-white">{quantityTotal}</span>
              <button
                className="btn px-3 py-0 border-start rounded-0 text-white fw-bold"
                onClick={increaseQuantityEnd}
              >
                +
              </button>
            </div>
            <button
              className="border-0 py-2 btn w-100 ms-3 text-white p-0 add_shadow custom_Font_Renner fw-bold product_price custom-button2 rounded bg_colour"
              onClick={() => {
                dispatch(
                  addToCart({
                    userId: userID || null,
                    quantity: quantityTotal,
                    productId: selectedProduct?._id,
                  })
                );
                navigate("/cart");
              }}
            >
              Add to cart
            </button>
          </div>
        </div>
      )}
      <Offcanvas show={show} onHide={handleCloseOffcanvas} placement="start">
        <Offcanvas.Header>
          <i
            className="bi bi-x-lg col d-flex align-items-center fs-1 text_colour text-end justify-content-end custom_Font_Renner"
            onClick={handleCloseOffcanvas}
          ></i>
        </Offcanvas.Header>
        <Offcanvas.Title className="text-start custom_Font_Renner w-100 p-4">
          <Link
            to="/"
            className="d-block mb-2 nav-link text-decoration-none fw-bold nav-link text_colour fs-6"
          >
            Home
          </Link>
          <Link
            to="/about"
            className="d-block mb-2 nav-link text-decoration-none fw-bold nav-link text_colour fs-6"
            onClick={GoingHandle}
          >
            About
          </Link>
          <div style={{ maxHeight: "65vh", overflowY: "auto" }}>

            <div className="last_border">
              <p className="text_colour fw-bold mb-2 fs-6" onClick={Morehandle}>
                More
                <span>
                  <i
                    className={`bi ${showMore ? "bi-chevron-up" : "bi-chevron-down"
                      } text_colour arrowfootnav ms-1`}
                  ></i>
                </span>
              </p>
              {showMore && (
                <div>
                  <ListGroup variant="" className="ms-3 mb-4">
                    <ListGroup.Item className="border-0 p-1">
                      <Link
                        to="/confirmorder"
                        className="d-block text-decoration-none text_colour endsborder"
                        onClick={GoingHandle}
                      >
                        Track your order
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0 p-1">
                      <Link
                        to="/location"
                        className="d-block text-decoration-none text_colour endsborder"
                        onClick={GoingHandle}
                      >
                        Locate our store
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0 p-1">
                      <Link
                        to="/faq"
                        className="d-block text-decoration-none text_colour endsborder"
                        onClick={GoingHandle}
                      >
                        FAQ
                      </Link>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              )}
            </div>
          </div>
          <Link
            to="/login"
            className="d-block mt-3 text-decoration-none fw-bold text_colour fs-6"
            onClick={GoingHandle}
          >
            Login
            <Link
              to="/"
              className="text-decoration-none text_colour fs-6"
              onClick={GoingHandle}
            >
              / Logout
            </Link>
          </Link>
        </Offcanvas.Title>
      </Offcanvas>

      <Offcanvas show={isVisible} onHide={handleShopMenu} placement="start">
        <Offcanvas.Header >  <i
          className="bi bi-x-lg col d-flex align-items-center fs-1 text_colour text-end justify-content-end custom_Font_Renner"
          onClick={handleShopMenu}
        ></i></Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            {/* <p className="text_colour fw-bold mb-2 fs-6" onClick={handleToggle}>
        Shop
        <span>
          <i
            className={`bi ${isVisible ? "bi-chevron-up" : "bi-chevron-down"} text_colour arrowfootnav ms-1`}
          ></i>
        </span>
      </p> */}
            {isVisible && (
              <div className="ms-1 last_border">
                <p className="text_colour fw-bold mb-2 custom_Font_Renner shopinsides" onClick={chocolateHandle}>
                  Shop
                  <span>
                    <i
                      className={`bi ${showChocolates ? "bi-chevron-up" : "bi-chevron-down"} text_colour arrowfootnav ms-1`}
                    ></i>
                  </span>
                  {showChocolates && (
                    <div>
                      <ListGroup variant="" className="ms-3 mb-4">
                        {shopCategory?.map((item) => (
                          <ListGroup.Item className="border-0 p-1" key={item._id}>
                            <Link
                              to={`/shop/${item._id}`}
                              className="d-block text-decoration-none fw-light text_colour endsborder"
                              onClick={handleShopMenu}
                            >
                              {item.name}
                            </Link>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>  
                  )}
                </p>

              </div>
            )}
          </div>
          <Link
            to="/login"
            className="d-block mt-3 text-decoration-none custom_Font_Renner fw-bold text_colour fs-6"
            onClick={handleShopMenu}
          >
            Login
            <Link
              to="/"
              className="text-decoration-none custom_Font_Renner fw-bold text_colour fs-6"
              onClick={handleShopMenu}
            >
              / Logout
            </Link>
          </Link>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default FooterNav;
