//------------------------------------R E D U C E R S-------------------------------------------------
import { ProductActionTypes } from "./constants"


const GET_PRODUCT_INITIAL_STATE = {
    productData: [],
    loading: false
}
const GET_CATEGORY_INITIAL_STATE = {
    categoryData: [],
    loading: false
}
const ADDTOCART_INITIAL_STATE = {
    addToCartData: [],
    loading: false
}


const productDataReducer = (state = GET_PRODUCT_INITIAL_STATE, action) => {
    switch (action.type) {
        case ProductActionTypes.GET_PRODUCT_LOADING:
            return {
                productData: state.productData,
                loading: true
            }
        case ProductActionTypes.GET_PRODUCT_SUCCESS:
            return {
                productData: action.payload,
                loading: false
            }
        case ProductActionTypes.GET_PRODUCT_ERROR:
            return {
                productData: action.payload,
                loading: false
            }
        default: return state
    }
}
const ordersDataReducer = (state = GET_PRODUCT_INITIAL_STATE, action) => {
    switch (action.type) {
        case ProductActionTypes.GET_USER_ORDERS_LOADING:
            return {
                productData: state.productData,
                loading: true
            }
        case ProductActionTypes.GET_USER_ORDERS_SUCCESS:
            return {
                productData: action.payload,
                loading: false
            }
        case ProductActionTypes.GET_USER_ORDERS_ERROR:
            return {
                productData: action.payload,
                loading: false
            }
        default: return state
    }
}
const categoryDataReducer = (state = GET_CATEGORY_INITIAL_STATE, action) => {
    switch (action.type) {
        case ProductActionTypes.GET_CATEGORY_LOADING:
            return {
                categoryData: state.categoryData,
                loading: true
            }
        case ProductActionTypes.GET_CATEGORY_SUCCESS:
            return {
                categoryData: action.payload,
                loading: false
            }
        case ProductActionTypes.GET_CATEGORY_ERROR:
            return {
                categoryData: action.payload,
                loading: false
            }
        default: return state
    }
}

const productDetailDataReducer = (state = GET_PRODUCT_INITIAL_STATE, action) => {
    switch (action.type) {
        case ProductActionTypes.GET_PRODUCT_DETAIL_LOADING:
            return {
                productData: state.productData,
                loading: true
            }
        case ProductActionTypes.GET_PRODUCT_DETAIL_SUCCESS:
            return {
                productData: action.payload,
                loading: false
            }
        case ProductActionTypes.GET_PRODUCT_DETAIL_ERROR:
            return {
                productData: action.payload,
                loading: false
            }
        default: return state
    }
}
const cartDetailDataReducer = (state = GET_PRODUCT_INITIAL_STATE, action) => {
    switch (action.type) {
        case ProductActionTypes.GET_CART_DETAIL_LOADING:
            return {
                ...state,
                loading: true
            };
        case ProductActionTypes.GET_CART_DETAIL_SUCCESS:
            return {
                ...state,
                productData: action.payload,
                loading: false
            };
        case ProductActionTypes.GET_CART_DETAIL_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

const addToCartReducer = (state = ADDTOCART_INITIAL_STATE, action) => {
    switch (action.type) {
        case ProductActionTypes.ADDTOCART_LOADING:
            return {
                ...state,
                loading: true
            };
        case ProductActionTypes.ADDTOCART_SUCCESS:
            return {
                ...state,
                addToCartData: action.payload,
                loading: false
            };
        case ProductActionTypes.ADDTOCART_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case ProductActionTypes.STATE_EMPTY_SUCCESS:
            return ADDTOCART_INITIAL_STATE
        default:
            return state;
    }
};

const proceedToBuyReducer = (state = ADDTOCART_INITIAL_STATE, action) => {
    switch (action.type) {
        case ProductActionTypes.PROCEED_TO_BUY_LOADING:
            return {
                ...state,
                loading: true
            };
        case ProductActionTypes.PROCEED_TO_BUY_SUCCESS:
            return {
                ...state,
                addToCartData: action.payload,
                loading: false
            };
        case ProductActionTypes.PROCEED_TO_BUY_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case ProductActionTypes.STATE_EMPTY_SUCCESS:
            return ADDTOCART_INITIAL_STATE
        default:
            return state;
    }
};

const deleteToCartReducer = (state = GET_CATEGORY_INITIAL_STATE, action) => {
    switch (action.type) {
        case ProductActionTypes.DELETETOCART_LOADING:
            return {
                categoryData: state.categoryData,
                loading: true
            }
        case ProductActionTypes.DELETETOCART_SUCCESS:
            return {
                categoryData: action.payload,
                loading: false
            }
        case ProductActionTypes.DELETETOCART_ERROR:
            return {
                categoryData: action.payload,
                loading: false
            }
        default: return state
    }
}

const productDetailsDataReducer = (state = GET_PRODUCT_INITIAL_STATE, action) => {
    switch (action.type) {
        case ProductActionTypes.GET_PRODUCT_DETAILS_LOADING:
            return {
                productData: state.productData,
                loading: true
            }
        case ProductActionTypes.GET_PRODUCT_DETAILS_SUCCESS:
            return {
                productData: action.payload,
                loading: false
            }
        case ProductActionTypes.GET_PRODUCT_DETAILS_ERROR:
            return {
                productData: action.payload,
                loading: false
            }
        default: return state
    }
}





export {
    productDataReducer, categoryDataReducer, productDetailDataReducer, addToCartReducer, cartDetailDataReducer, deleteToCartReducer, proceedToBuyReducer, ordersDataReducer
}