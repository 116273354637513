export const AuthActionTypes = {
    API_RESPONSE_SUCCESS: '@@auth/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@auth/API_RESPONSE_ERROR',

    LOGIN_USER: '@@auth/LOGIN_USER',
    VERIFY_OTP: '@@auth/VERIFY_OTP',
    LOGOUT_USER: '@@auth/LOGOUT_USER',
    SIGNUP_USER: '@@auth/SIGNUP_USER',
    FORGOT_PASSWORD: '@@auth/FORGOT_PASSWORD',
    FORGOT_PASSWORD_CHANGE: '@@auth/FORGOT_PASSWORD_CHANGE',

    RESET: '@@auth/RESET',


    LOGIN_FIRST: "@@auth/LOGIN_FIRST",
    LOGIN_LOADING: "@@auth/LOGIN_LOADING",
    LOGIN_SUCCESS: "@@auth/LOGIN_SUCCESS",
    LOGIN_ERROR: "@@auth/LOGIN_ERROR",
    LOGIN_RESET: "@@auth/LOGIN_RESET",

    REGISTER_FIRST: "@@auth/REGISTER_FIRST",
    REGISTER_LOADING: "@@auth/REGISTER_LOADING",
    REGISTER_SUCCESS: "@@auth/REGISTER_SUCCESS",
    REGISTER_ERROR: "@@auth/REGISTER_ERROR",
    REGISTER_RESET: "@@auth/REGISTER_RESET",


    LOGOUT_USER_FIRST: "@@auth/LOGOUT_USER_FIRST",
    LOGOUT_USER: "@@auth/LOGOUT_USER",


    VERIFY_OTP_FIRST: "@@auth/VERIFY_OTP_FIRST",
    VERIFY_OTP_LOADING: "@@auth/VERIFY_OTP_LOADING",
    VERIFY_OTP_SUCCESS: "@@auth/VERIFY_OTP_SUCCESS",
    VERIFY_OTP_ERROR: "@@auth/VERIFY_OTP_ERROR",
    VERIFY_OTP_RESET: "@@auth/VERIFY_OTP_RESET",

    STATE_EMPTY_FIRST: '@@auth/STATE_EMPTY_FIRST',
    STATE_EMPTY_SUCCESS: '@@auth/STATE_EMPTY_SUCCESS',
};
