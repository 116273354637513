//------------------------------------A C T I O N S----------------------------------------------------
// @flow
import { ProductActionTypes } from './constants';

type AuthAction = { type: string, payload: {} | string };

// common success


export const getProductActions = (data): AuthAction => ({
    type: ProductActionTypes.GET_PRODUCT_FIRST,
    data
});

export const getUserOrderActions = (data): AuthAction => ({
    type: ProductActionTypes.GET_USER_ORDERS_FIRST,
    data
});

export const getCategoryActions = (data): AuthAction => ({
    type: ProductActionTypes.GET_CATEGORY_FIRST,
    data
});
export const getProductDetailActions = (data): AuthAction => ({
    type: ProductActionTypes.GET_PRODUCT_DETAIL_FIRST,
    data
});
export const getCartDetailActions = (data): AuthAction => ({
    type: ProductActionTypes.GET_CART_DETAIL_FIRST,
    data
});
export const addToCart = (data): AuthAction => ({
    type: ProductActionTypes.ADDTOCART_FIRST,
    data
});

export const proceedToBuy = (data): AuthAction => ({
    type: ProductActionTypes.PROCEED_TO_BUY_FIRST,
    data
});
export const deleteToCart = (data): AuthAction => ({
    type: ProductActionTypes.DELETETOCART_FIRST,
    data
});

export const stateEmpty = () => ({
    type: ProductActionTypes.STATE_EMPTY_FIRST,
});