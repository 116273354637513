// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    login as loginApi,
    // logout as logoutApi,
    signup as signupApi,
    forgotPassword as forgotPasswordApi,
    otp as otpApi,
    forgotPasswordConfirm,
} from '../../mozimo/helpers/api/index';

import { APICore, setAuthorization } from '../../mozimo/helpers/api/apiCore';
import { authApiResponseSuccess, authApiResponseError } from './actions';
import { AuthActionTypes } from './constants';

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - email and password
 * 
 */
function* login({ payload: data }) {
    try {
        const response = yield call(loginApi, data);
        const user = response;
        console.log({ response }, 'login')
        let userInfo = {
            message: user?.data,
            status: user?.status
        };
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, userInfo));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
        api.setLoggedInUser(error);
    }
}

function* otpVerified({ payload: data }) {
    try {
        const response = yield call(otpApi, data);
        const userOtp = response?.data?.response;
        // localStorage.setItem('otpData', JSON.stringify(userOtp))
        console.log({ response, userOtp, }, 'otpp verified')
        let userOtpData = {
            id: userOtp?.response?._id,
            name: userOtp?.response?.firstName + ' ' + userOtp?.response?.lastName,
            email: userOtp?.response?.email,
            phoneNumber: userOtp?.response?.phoneNumber,
            phoneVerified: userOtp?.response?.phoneVerified,
            emailVerified: userOtp?.response?.emailVerified,
            role: userOtp?.response?.role,
            token: userOtp?.token,
            message: userOtp?.message,
            status: userOtp?.status,
            registered: userOtp.register
        };
        // NOTE - You can change this according to response format from your api
        api.setLoggedInUser(userOtp);
        setAuthorization(userOtp?.token);
        yield put(authApiResponseSuccess(AuthActionTypes.VERIFY_OTP, userOtp));
    } catch (error) {
        console.log({ error })
        yield put(authApiResponseError(AuthActionTypes.VERIFY_OTP, error));
        api.setLoggedInUser(error);
        setAuthorization(null);
    }
}

/**
 * Logout the user
 */
// function* logout() {
//     try {
//         yield call(logoutApi);
//         api.setLoggedInUser(null);
//         setAuthorization(null);
//         localStorage.removeItem('Mozimo_user')
//         yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
//     } catch (error) {
//         yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
//     }
// }

function* signup({ payload: data }) {
    try {
        const response = yield call(signupApi, data);
        const user = response?.data?.response;
        // localStorage.setItem('verifiedUser', JSON.stringify(user))
        console.log({ user }, 'register api ')
        api.setLoggedInUser(user);
        setAuthorization(user?.token);
        yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* forgotPassword({ payload: { email } }) {
    try {
        const response = yield call(forgotPasswordApi, { email });
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    }
}

function* forgotPasswordChange({ payload: { data } }) {
    try {
        const response = yield call(forgotPasswordConfirm, data);
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD_CHANGE, response.data));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD_CHANGE, error));
    }
}

function* StateEmptyFunction() {
    try {
        yield put({
            type: AuthActionTypes.STATE_EMPTY_SUCCESS,
            payload: {},
        });

    } catch (error) {

    }
}

export function* watchLoginUser(): any {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
    yield takeEvery(AuthActionTypes.VERIFY_OTP, otpVerified);
}

// export function* watchLogout(): any {
//     yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
// }

export function* watchSignup(): any {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}
export function* watchStateEmpty(): any {
    yield takeEvery(AuthActionTypes.STATE_EMPTY_FIRST, StateEmptyFunction);
}

// export function* watchForgotPassword(): any {
//     yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
// }

// export function* watchForgotPasswordChange(): any {
//     yield takeEvery(AuthActionTypes.FORGOT_PASSWORD_CHANGE, forgotPasswordChange);
// }

function* authSaga(): any {
    yield all([
        fork(watchLoginUser),
        // fork(watchLogout),
        fork(watchSignup),
        fork(watchStateEmpty),
        // fork(watchForgotPassword),
        // fork(watchForgotPasswordChange),
    ]);
}

export default authSaga;
