import { addAddressTypes } from "./constants"



const ADDADDRESS_INITIAL_STATE = {
    addAddressData: [],
    loading: false
}

const INITIAL_PAYMENT_STATE = {
    paymentData: null,
    loading: false,
    error: null,
};
const INITIAL_ENQUARY_STATE = {
    enquaryData: null,
    loading: false,
    error: null,
};

const VERIFY_PAYMENT_INITIAL_STATE = {
    paymentVerified: null,
    loading: false,
    error: null,
};


const addAddressReducer = (state = ADDADDRESS_INITIAL_STATE, action) => {
    switch (action.type) {
        case addAddressTypes.ADD_ADDRESS_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case addAddressTypes.ADD_ADDRESS_SUCCESS:
            return {
                addAddressData: action.payload,
                loading: false
            }
        case addAddressTypes.ADD_ADDRESS_ERROR:
            return {
                addAddressData: action.payload,
                loading: false
            }
        case addAddressTypes.STATE_EMPTY_SUCCESS:
            return ADDADDRESS_INITIAL_STATE
        default: return state
    }
}

const paymentReducer = (state = INITIAL_PAYMENT_STATE, action) => {
    switch (action.type) {
        case addAddressTypes.PAYMENT_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case addAddressTypes.PAYMENT_SUCCESS:
            return {
                ...state,
                paymentData: action.payload,
                loading: false,
            };
        case addAddressTypes.PAYMENT_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case addAddressTypes.STATE_EMPTY_SUCCESS:
            return INITIAL_PAYMENT_STATE
        default:
            return state;
    }
};
const enquaryReducer = (state = INITIAL_ENQUARY_STATE, action) => {
    switch (action.type) {
        case addAddressTypes.ADD_ENQUARY_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case addAddressTypes.ADD_ENQUARY_SUCCESS:
            return {
                ...state,
                enquaryData: action.payload,
                loading: false,
            };
        case addAddressTypes.ADD_ENQUARY_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case addAddressTypes.STATE_EMPTY_SUCCESS:
            return INITIAL_ENQUARY_STATE
        default:
            return state;
    }
};

const verifyPaymentReducer = (state = VERIFY_PAYMENT_INITIAL_STATE, action) => {
    switch (action.type) {
        case addAddressTypes.VERIFY_PAYMENT_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case addAddressTypes.VERIFY_PAYMENT_SUCCESS:
            return {
                ...state,
                paymentVerified: action.payload,
                loading: false,
            };
        case addAddressTypes.VERIFY_PAYMENT_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case addAddressTypes.STATE_EMPTY_SUCCESS:
            return VERIFY_PAYMENT_INITIAL_STATE
        default:
            return state;
    }
};


export {
    addAddressReducer, paymentReducer, verifyPaymentReducer, enquaryReducer
}