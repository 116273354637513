//------------------------------------A P I-----------------------------------------------------------------
import { APICore } from '../../mozimo/helpers/api/apiCore';
import * as URL from '../../mozimo/helpers/api/apiEndPoint';

const api = new APICore();

function homePageData(params: any): any {
    return api.get(`${URL.GET_HOME_PAGE_DATA}`);

} function beandStoryData(params: any): any {
    const { data } = params;
    return api.get(`${URL.GET_BRAND_STORY}${data}`);
}
 function logoData(params: any): any {
    return api.get(`${URL.GET_LOGO}`);
}
function instaVideoData(params: any): any {
    const { data } = params;
    return api.get(`${URL.GET_INSTA_VIDEO}${data}`);
}
function instaImageData(params: any): any {
    return api.get(`${URL.GET_INSTA_IMAGE}`);
}

function collectionData(params: any): any {
    return api.get(`${URL.GET_COLLECTION}`);
}

function particularCollectionData(params: any): any {
    const { collectionId,sortBy,search,id } = params?.data;
    console.log({id,sortBy,search })
    return api.get(`${URL.GET_PARTICULAR_COLLECTION}?collectionId=${collectionId}&search=${search}&sortBy=${sortBy}&_id=${id}`);
}

export { homePageData, beandStoryData, instaVideoData, instaImageData, collectionData, particularCollectionData,logoData };