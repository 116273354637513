//------------------------------------C O N S T A N T S----------------------------------------------
export const HomeActionTypes = {
    // HOME DATA
    HOME_DATA_FIRST: '@@auth/HOME_DATA_FIRST',
    HOME_DATA_LOADING: '@@auth/HOME_DATA_LOADING',
    HOME_DATA_SUCCESS: '@@auth/HOME_DATA_SUCCESS',
    HOME_DATA_ERROR: '@@auth/HOME_DATA_ERROR',
    // BRAND STORY
    BRAND_STORY_FIRST: '@@auth/BRAND_STORY_FIRST',
    BRAND_STORY_LOADING: '@@auth/BRAND_STORY_LOADING',
    BRAND_STORY_SUCCESS: '@@auth/BRAND_STORY_SUCCESS',
    BRAND_STORY_ERROR: '@@auth/BRAND_STORY_ERROR',
    // LOGO
    LOGO_FIRST: '@@auth/LOGO_FIRST',
    LOGO_LOADING: '@@auth/LOGO_LOADING',
    LOGO_SUCCESS: '@@auth/LOGO_SUCCESS',
    LOGO_ERROR: '@@auth/LOGO_ERROR',
    // INSTA VIDEO
    INSTA_VIDEO_FIRST: '@@auth/INSTA_VIDEO_FIRST',
    INSTA_VIDEO_LOADING: '@@auth/INSTA_VIDEO_LOADING',
    INSTA_VIDEO_SUCCESS: '@@auth/INSTA_VIDEO_SUCCESS',
    INSTA_VIDEO_ERROR: '@@auth/INSTA_VIDEO_ERROR',
    STATE_EMPTY_FIRST: '@@auth/STATE_EMPTY_FIRST',
    // INSTA IMAGE
    INSTA_IMAGE_FIRST: '@@auth/INSTA_IMAGE_FIRST',
    INSTA_IMAGE_LOADING: '@@auth/INSTA_IMAGE_LOADING',
    INSTA_IMAGE_SUCCESS: '@@auth/INSTA_IMAGE_SUCCESS',
    INSTA_IMAGE_ERROR: '@@auth/INSTA_IMAGE_ERROR',
    //Collection data
    COLLECTION_DATA_FIRST: '@@auth/COLLECTION_DATA_FIRST',
    COLLECTION_DATA_LOADING: '@@auth/COLLECTION_DATA_LOADING',
    COLLECTION_DATA_SUCCESS: '@@auth/COLLECTION_DATA_SUCCESS',
    COLLECTION_DATA_ERROR: '@@auth/COLLECTION_DATA_ERROR',
    COLLECTION_DATA_RESET: '@@auth/COLLECTION_DATA_RESET',

    PARTICULAR_COLLECTION_DATA_FIRST: '@@auth/PARTICULAR_COLLECTION_DATA_FIRST',
    PARTICULAR_COLLECTION_DATA_LOADING: '@@auth/PARTICULAR_COLLECTION_DATA_LOADING',
    PARTICULAR_COLLECTION_DATA_SUCCESS: '@@auth/PARTICULAR_COLLECTION_DATA_SUCCESS',
    PARTICULAR_COLLECTION_DATA_ERROR: '@@auth/PARTICULAR_COLLECTION_DATA_ERROR',
    PARTICULAR_COLLECTION_DATA_RESET: '@@auth/PARTICULAR_COLLECTION_DATA_RESET',


    STATE_EMPTY_FIRST: '@@auth/STATE_EMPTY_FIRST',
    STATE_EMPTY_SUCCESS: '@@auth/STATE_EMPTY_SUCCESS',


};