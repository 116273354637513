// @flow
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../pages/footer/Footer';
import Header from '../pages/header/Nav';

const loading = () => <div className=""></div>;

type DefaultLayoutProps = {};

const DefaultLayout = (props: DefaultLayoutProps) => {

    return (
        <Suspense fallback={loading()}>
            <Header />
            <div className="" style={{ minHeight: '80vh' }}>
                <Outlet />
            </div>
            <Footer />
        </Suspense>
    );
};
export default DefaultLayout;
