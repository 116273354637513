//-----HOME--PAGE--------------
export const GET_HOME_PAGE_DATA = '/api/banner/get';
export const GET_BRAND_STORY = '/api/sigleimage&text/get/';
export const GET_LOGO = '/api/spot/spot/getAll';
export const GET_INSTA_VIDEO = '/api/sigleimage&text/get/';
export const GET_INSTA_IMAGE = '/api/user/getInstagramPost';
export const GET_PRODUCT = '/api/product/get?categoryId=';
export const GET_ORDERS = '/api/order/get/';
export const GET_CATEGORY = '/api/category/get';
export const GET_PRODUCT_DETAIL = '/api/category/product/details?';
export const GET_CART_DETAIL = '/api/cart/get/';
export const ADDTOCART = '/api/cart/add';
export const ADDADDRESS = '/api/user/address/add';
export const CARTDELETE = '/api/cart/delete';
export const GET_COLLECTION = '/api/category/ourcolection/get';
export const GET_PARTICULAR_COLLECTION = '/api/product/collection';
export const PROCEED_TO_BUY = '/api/cart/proceedtobuy';
export const CREATE_PAYMENT_ORDER = '/api/order/create';
export const ENQUARYFORM = '/api/enquiry/saveEnquiry';
export const VERIFY_PAYMENT = '/api/order/verifypayment';
export const GET_ORDER = '/api/order/get/'



//-----ABOUT--PAGE--------------


//-----SHOPPING--PAGE--------------
