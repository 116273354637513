import React, { useEffect, useState, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Nav, Navbar, Container, NavDropdown, Dropdown, Modal, Form, Offcanvas, Accordion, ListGroup, Card, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ChocalateIconSmallNavbar, GiftingSmallNavbar, HomeIconSmallNavbar, LocationIconSmallNavbar, Logo, Logo1, MenuIconSmallNavbar, navbarIconPerson, navbarIconSeacrh, navbatIconCart, navbatIconCartmobile } from '../../assets/img';
import { useDispatch, useSelector } from 'react-redux';
import { getCartDetailActions, getProductActions } from '../../../redux/Shop/action';
import { getUserFromSession } from '../../helpers/api/apiCore';
import FooterNav from './FooterNav';
import { IoIosArrowDown } from "react-icons/io";
import { InputGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { getCollectionActions } from '../../../redux/actions';




const NavBar = () => {
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [scrollTimeout, setScrollTimeout] = useState(null);
  const user = getUserFromSession()
  const dispatch = useDispatch()
  const searchRef = useRef(null);

  const [show1, setShow1] = useState(false);

  const handleClose2 = () => setShow1(false);
  const handleShow2 = () => setShow1(true);
  const userId = user?._id

  const cartDetail = useSelector((state) => state.cartDetailDataReducer.productData?.response || []);

  useEffect(() => {
    if (user) {
      dispatch(getCartDetailActions(userId));
    }
    dispatch(getCollectionActions());
  }, [dispatch, userId]);
  const handleScroll = () => {
    if (window.scrollY > lastScrollY) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
    setLastScrollY(window.scrollY);

    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    setScrollTimeout(
      setTimeout(() => {
        setShowNavbar(false);
      }, 100000)
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  }, [lastScrollY, scrollTimeout]);
  // for offcanvas search open in mobile phone 
  // const [showSearchModal, setShowSearchModal] = useState(false);

  // const toggleSearchModal = () => {
  //   setShowSearchModal((prevShowSearch) => !prevShowSearch);
  // };
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');

  const handleClose = () => {
    setShowSearchBar(false);
    setShow1(false)

  } 
const handleSearchInputChangefirst = (e) =>{
  setSearch(e.target.value)
}
  const handleSearchInputChangeSecond = (e) => {
    const inputValue = e.target.value;

    setSearch(inputValue);
    if (inputValue.length > 0) {
      setShow1(true);
    } else {
      setShow1(false)
    }
  };


  const [showSearchBar, setShowSearchBar] = useState(false);
  const toggleSearch = () => {
    setShowSearchBar((prevShowSearch) => !prevShowSearch);
    setSearch('')
  };

  useEffect(() => {
    dispatch(getProductActions({ categoryId: '', search: search, sortBy: '' }))
  }, [search])

  const Products = useSelector((state) => state?.productDataReducer?.productData?.response || []);
  const collection = useSelector((state) => state?.collectionDataReducer?.homeData?.response || []);
  const [showGifting, setShowGifting] = useState(false);
  const dropdownRef = useRef(null);

  const toggleGiftingDropdown = (e) => {
    e.stopPropagation(); // Prevent closing when clicking on the dropdown item
    setShowGifting(!showGifting);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowGifting(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    console.log("1234567890");
    return () => {
      console.log("1234567890");

      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const local = localStorage.getItem('productData')
  const ProductDetail = JSON.parse(local)


  return (
    <>
      <Navbar
        bg="white"
        className={`px-md-5 py-0  navbar_custom sticky-top  position-reative ${showNavbar ? 'd-none d-lg-block' : 'd-none'}`}
        variant="light"
        expand="lg"
      >
        <Container fluid className='' >
          <div className="d-flex align-items-center">
            <NavLink className="navbar-brand order-lg-0" to="/">
              <img className=" rounded-circle  bg-white " src={Logo1} alt="Logo" style={{ width: '50%', top: "0", objectFit: 'cover' }} />
            </NavLink>
          </div>

          <Navbar.Collapse id="basic-navbar-nav" className="order-lg-2 my-2">
            <Nav className="ms-auto custom_Font_Renner fs-4 fw-light">
              <Nav.Link
                as={NavLink}
                to="/about"
                className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link mx-5')}
              >
                About us
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/shop"
                className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
              >
                Shop
              </Nav.Link>
              <NavDropdown
                title={<span>More <IoIosArrowDown style={{ color: '#7C402E' }} /></span>}
                // id="basic-nav-dropdown"
                className="me-3 custom_Font_Renner"
              >
                {/* <NavDropdown.Item as={NavLink} to="/contact">
                  Contact
                </NavDropdown.Item> */}
                <NavDropdown.Item as={NavLink} to="/faq">
                  FAQ
                </NavDropdown.Item>

                <NavDropdown.Item as={NavLink} to="/confirmorder">
                  Track Your Order
                </NavDropdown.Item>

                <div ref={dropdownRef}>
                  <NavDropdown.Item onClick={toggleGiftingDropdown} className="d-flex align-items-center justify-content-between">
                    <span>Gifting</span>
                    <IoIosArrowDown style={{ color: '#7C402E' }} />
                  </NavDropdown.Item>

                  {showGifting && (
                    <div className="mt-2">
                      {collection?.map((item) => (
                        <NavDropdown.Item
                          key={item?._id}
                          as={NavLink}
                          className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
                          to={`/collections/${item?.name}/${item?._id}`}
                          state={item}
                        >
                          {item?.name}
                        </NavDropdown.Item>
                      ))}
                    </div>
                  )}
                </div>

                <NavDropdown.Item as={NavLink} to="/location">
                  Locate Our Store
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/bulk">
                  Bulk
                </NavDropdown.Item>
              </NavDropdown>
              {/* {showSearchBar && (
                <Form className='ps-4'>
                  <Form.Group controlId="searchInput">
                    <InputGroup>
                      <Form.Control
                        type="search"
                        className="custom_Font_Renner px-2 custom-input"
                        placeholder="Search..."
                        autoFocus
                        value={search}
                        onChange={handleSearchInputChange}
                        style={{
                          paddingLeft: '40px',
                          borderRadius: '15px' // Rounded corners
                        }}
                      />
                    </InputGroup>
                  </Form.Group>
                </Form>
              )} */}

              <div className="search-box">
                {showSearchBar && (
                  <button className="btn-search " onClick={toggleSearch}>
                    <FaSearch />
                  </button>
                )}

                {showSearchBar && (
                  <input
                    type="text"
                    className="input-search"
                    placeholder="Type to Search..."
                    value={search}
                    onChange={handleSearchInputChangefirst}
                  />
                )}
                {search && showSearchBar && (
                  <div
                    ref={searchRef}
                    style={{
                      position: 'absolute',
                      top: '60px',
                      left: '',
                      width: '100%',
                      zIndex: '999',
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    }}
                    onClick={handleClickOutside}
                  >
                    <Card className='border-none'>
                      <Card.Body className='border-none p-0 scroll-hidden' style={{ height: '300px', overflowY: 'scroll' }}>
                        <style jsx>
                          {`
        .scroll-hidden::-webkit-scrollbar {
          display: none; /* Hides scrollbar for Chrome, Safari, and Opera */
        }
        .scroll-hidden {
          -ms-overflow-style: none;  /* Hides scrollbar for Internet Explorer and Edge */
          scrollbar-width: none;  /* Hides scrollbar for Firefox */
        }
      `}
                        </style>

                        {Products.length > 0 ? (
                          <>
                            {Products.map((data) => (
                              <Row
                                key={data._id}
                                className='my-2 mx-1 search-option-hover rounded p-2'
                                style={{ border: '1px solid rgb(201 186 187)', padding: '1px 1px' }}
                              >
                                <Col lg={3} className='d-flex justify-content-center align-items-center p-0'>
                                  <img
                                    src={data.frontImage}
                                    className='img-fluid rounded-pill shadow'
                                    style={{ width: '75px', height: '75px', objectFit: 'cover' }}
                                    alt="Product"
                                  />
                                </Col>
                                <Col lg={6}>
                                  <div>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip id="more-tooltip" style={{ fontSize: "12px" }}>{data.name}</Tooltip>}
                                    >
                                      <p className='custom_Font_Renner text_colour m-0 mt-2' style={{ fontSize: "14px" }}>
                                        {data.name.slice(0, 22) || 'Product'}
                                      </p>
                                    </OverlayTrigger>
                                    <p className='custom_Font_Renner text_colour m-0 small' style={{ fontSize: "12px" }}>
                                      Category: {data.categoryId?.name || 'Category'}
                                    </p>
                                    <p className='custom_Font_Renner text_colour small' style={{ fontSize: "12px" }}>
                                      Price: ₹ {data.price}
                                    </p>
                                  </div>
                                </Col>
                                <Col lg={3} className='text-start p-3'>
                                  <div className='text-center mt-2'>
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={<Tooltip id="more-tooltip">View {data.name}</Tooltip>}
                                    >
                                      <Link to={'/product-details'} state={{ selectedProduct: data }}>
                                        <i className="bi bi-arrow-right-circle text-dark fs-3" style={{ cursor: 'pointer' }} onClick={handleClose}></i>
                                      </Link>
                                    </OverlayTrigger>
                                  </div>
                                </Col>
                              </Row>
                            ))}
                          </>
                        ) : (
                          <div className='text-danger custom_Font_Renner fw-bold text-center fs-5 d-flex justify-content-center align-items-center h-100' >
                            <p className='m-0'>
                              No Products found for {search}!
                            </p>
                          </div>

                        )}
                      </Card.Body>
                    </Card>

                  </div>
                )}
              </div>



              {!showSearchBar && (
                <Nav.Link className="d-none d-md-block nav-link2">
                  <img
                    src={navbarIconSeacrh}
                    style={{ width: "30px", height: "30px", objectFit: "contain" }}
                    onClick={toggleSearch}
                    alt="Search Icon"
                  />
                </Nav.Link>
              )}

              <Nav.Link as={NavLink} to={!user ? '/login' : '/profile'} className="d-none d-lg-block nav-link2">
                <img
                  src={navbarIconPerson}
                  className={`bi ${!user ? 'text-danger' : 'text-success'}`}
                  style={{ width: "30px", height: "30px", objectFit: "contain" }}
                  alt="User Icon"
                />
              </Nav.Link>

              <Nav.Link as={NavLink} to="/cart" className="d-none d-lg-block nav-link2">
                <div className="d-flex">
                  <img
                    src={navbatIconCart}
                    style={{ width: "35px", height: "35px", objectFit: "contain" }}
                    alt="Cart Icon"
                  />
                  {(cartDetail?.length > 0 || ProductDetail?.length > 0) && (
                    <p className="p-0 bg_colour rounded-circle py-1 text-white px-2 cart_property">
                      {cartDetail?.length > 0 ? cartDetail?.length : ProductDetail?.length}
                    </p>
                  )}
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <nav className="responsive_nav_mob sticky-top d-md-none ">
        <div className="nav_mob">
          <Nav.Link className='text-decoration-none' href='/'>
            <Nav.Link className='text-decoration-none' href='/'>
              <img src={Logo} alt="Logo" className="mobile_logo_main" />
            </Nav.Link>
          </Nav.Link>
        </div>
        <Offcanvas show={show1} onHide={handleClose2} placement="end">
          <Offcanvas.Header className='d-flex justify-content-between align-items-center mb-0'>
          <Offcanvas.Title className='custom_Font_Renner text_colour'>Search</Offcanvas.Title>

          <i
            className="bi bi-x-lg col d-flex align-items-center fs-1 text_colour text-end justify-content-end custom_Font_Renner"
            onClick={handleClose2}
          ></i>

          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form>
              <div className="search-input-wrapper2">
                <Form.Control type="text" placeholder="Search..." value={search}
                  onChange={handleSearchInputChangeSecond}
                  className="search-input2" />
                <FaSearch className="search-icon2" />
              </div>
              {search && (
                <div className='border-none'>
                  <div className='border-none p-0 scroll-hidden' style={{ height: '80vh', overflowY: 'scroll' }}>
                    <style jsx>
                      {`
.scroll-hidden::-webkit-scrollbar {
display: none; /* Hides scrollbar for Chrome, Safari, and Opera */
}
.scroll-hidden {
-ms-overflow-style: none;  /* Hides scrollbar for Internet Explorer and Edge */
scrollbar-width: none;  /* Hides scrollbar for Firefox */
}
`}
                    </style>

                    {Products.length > 0 ? (
                      <>
                        {Products.map((data) => (
                          <Row
                            key={data._id}
                            className='my-2 mx-1 search-option-hover rounded p-2'
                            style={{ border: '1px solid rgb(201 186 187)', padding: '1px 1px' }}
                          >
                            <Col md={3} xs={3} className='d-flex justify-content-center align-items-center p-0'>
                              <img
                                src={data.frontImage}
                                className='img-fluid rounded-pill shadow'
                                style={{ width: '75px', height: '75px', objectFit: 'cover' }}
                                alt="Product"
                              />
                            </Col>
                            <Col md={6} xs={6}>
                              <div>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id="more-tooltip" style={{ fontSize: "12px" }}>{data.name}</Tooltip>}
                                >
                                  <p className='custom_Font_Renner text_colour m-0 mt-2' style={{ fontSize: "14px" }}>
                                    {data.name.slice(0, 22) || 'Product'}
                                  </p>
                                </OverlayTrigger>
                                <p className='custom_Font_Renner text_colour m-0 small' style={{ fontSize: "12px" }}>
                                  Category: {data.categoryId?.name || 'Category'}
                                </p>
                                <p className='custom_Font_Renner text_colour small' style={{ fontSize: "12px" }}>
                                  Price: ₹ {data.price}
                                </p>
                              </div>
                            </Col>
                            <Col md={3} xs={3} className='text-start p-3'>
                              <div className='text-center mt-2'>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip id="more-tooltip">View {data.name}</Tooltip>}
                                >
                                  <Link to={'/product-details'} state={{ selectedProduct: data }}>
                                    <i className="bi bi-arrow-right-circle text-dark fs-3" style={{ cursor: 'pointer' }} onClick={handleClose}></i>
                                  </Link>
                                </OverlayTrigger>
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </>
                    ) : (
                      <div className='text-danger custom_Font_Renner fw-bold text-center fs-5 d-flex justify-content-center align-items-center h-100' >
                        <p className='m-0'>
                          No Products found for {search}!
                        </p>
                      </div>

                    )}
                  </div>
                </div>
              )}

            </Form>
          </Offcanvas.Body>
        </Offcanvas>
        <div className="navbar-icons">
          <div className="d-flex align-items-center justify-content-between">
            <img src={navbarIconSeacrh} className='me-4' style={{ width: "13.52px", height: "17.73px" }} onClick={handleShow2} alt="" />
            <Link as={NavLink} to={!user ? '/login' : '/profile'} className="me-4 nav-link2">
              <img
                src={navbarIconPerson}
                className={`bi ${!user ? 'text-danger' : 'text-success'}`}
                style={{ width: "16.9px", height: "18.5px" }}
                alt="User Icon"
              />
            </Link>
            <Link to={"/cart"} className='d-flex text-decoration-none'>
              <img src={navbatIconCart} className='' style={{ width: "14.28px", height: "18.15px" }} alt="" />
              <p className="bg_colour rounded-circle py-0 text-white px-0 m-0 cart_property_mobile" style={{ width: "12.28px", fontSize: '10px', height: "12.15px" }}>
                {cartDetail?.length}
              </p>

            </Link>
          </div>
        </div>
      </nav>

      <div className='d-block d-md-none add-nav-class'>
        <FooterNav />
      </div>
    </>
  );
};

export default NavBar;

