

import { all, fork, put, takeEvery, call, takeLatest } from 'redux-saga/effects';
import { OrderActionTypes } from './constant';

import { orderData } from './api';
/**
 * Login the user
 * @param {*} payload - username and password
 */



function* getOrderFunction(data) {
    try {
        yield put({
            type: OrderActionTypes.ORDER_DATA_LOADING,
            payload: {},
        });
        const response = yield call(orderData, data);
        // console.log(response,'api response')
        if (response?.status === 200) {
            yield put({
                type: OrderActionTypes.ORDER_DATA_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: OrderActionTypes.ORDER_DATA_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: OrderActionTypes.ORDER_DATA_ERROR,
            payload: error,
        });
    }
}


export function* OrderData() {
    yield takeEvery(OrderActionTypes.ORDER_DATA_FIRST, getOrderFunction);
  
}

function* OrderSaga() {
    yield all([
        fork(OrderData)
    ]);
}
export default OrderSaga;