// @flow
import { combineReducers } from 'redux';
import { homeDataReducer, logoReducer,brandStoryReducer, instaVideoReducer, instaImageReducer, collectionDataReducer, particularCollectionDataReducer } from './home/reducer';
import { productDataReducer, ordersDataReducer, categoryDataReducer, productDetailDataReducer, deleteToCartReducer, cartDetailDataReducer, addToCartReducer, proceedToBuyReducer } from './Shop/reducer';
import Auth from './auth/reducers';
import { addAddressReducer, paymentReducer, verifyPaymentReducer, enquaryReducer } from './payments/reducer';
import { orderDataReducer } from './orders/reducer';

export default (combineReducers({
    homeDataReducer, brandStoryReducer,logoReducer, instaVideoReducer, instaImageReducer, collectionDataReducer, particularCollectionDataReducer, productDataReducer, ordersDataReducer, Auth, categoryDataReducer,
    productDetailDataReducer, addToCartReducer, proceedToBuyReducer, cartDetailDataReducer, addAddressReducer, paymentReducer, verifyPaymentReducer, deleteToCartReducer, enquaryReducer , orderDataReducer
}): any);

