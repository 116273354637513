//------------------------------------A P I-----------------------------------------------------------------
import { APICore } from '../../mozimo/helpers/api/apiCore';
import * as URL from '../../mozimo/helpers/api/apiEndPoint';

const api = new APICore();

function productData(params: any): any {
        const { categoryId, search, sortBy } = params?.data
        return api.get(`${URL.GET_PRODUCT}${categoryId}&search=${search}&sortBy=${sortBy}`);
}
function ordersData(params: any): any {
        const { userId } = params?.data
        return api.get(`${URL.GET_ORDERS}${userId}`);
}
function categoryData(params: any): any {
        return api.get(`${URL.GET_CATEGORY}`);
}
function productDetailData(params: any): any {
        const { searchTerm, sortOrder, categoryId } = params?.data;
        return api.get(`${URL.GET_PRODUCT_DETAIL}searchTerm=${searchTerm}&sortOrder=${sortOrder}&categoryId=${categoryId}`);
}
function cartDetailData(params: any): any {
        const { data } = params
        return api.get(`${URL.GET_CART_DETAIL}${data}`);
}
function addToCart(params: any): any {
        const { data } = params
        return api.create(URL.ADDTOCART, data);
}
function proceedToBuy(params: any): any {
        const { data } = params
        return api.create(URL.PROCEED_TO_BUY, data);
}
function deleteToCart(params: any): any {
        const { data } = params
        return api.create(URL.CARTDELETE, data);
}


export { productData, categoryData, productDetailData, addToCart, cartDetailData, deleteToCart, proceedToBuy, ordersData }
