
import { OrderActionTypes } from "./constant"

const ORDER_DATA_INITIAL_STATE = {
    orderData: [],
    loading: false
}

const orderDataReducer = (state = ORDER_DATA_INITIAL_STATE, action) => {
    switch (action.type) {
        case OrderActionTypes.ORDER_DATA_LOADING:
            return {
                orderData: state.orderData,
                loading: true
            }
        case OrderActionTypes.ORDER_DATA_SUCCESS:
            return {
                orderData: action.payload,
                loading: false
            }
        case OrderActionTypes.ORDER_DATA_ERROR:
            return {
                orderData: action.payload,
                loading: false
            }
        default: return state
    }
}
export {
    orderDataReducer, 
}