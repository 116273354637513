// @flow
import { all } from 'redux-saga/effects';
import homeSaga from './home/saga';
import productSaga from './Shop/saga';
import authSaga from './auth/saga';
import paymentSaga from './payments/saga';
import OrderSaga from './orders/saga';

export default function* rootSaga(): any {
    yield all([
        homeSaga(),
        productSaga(),
        authSaga(),
        paymentSaga(),
        OrderSaga(),
    ]);
}
