//------------------------------------A P I-----------------------------------------------------------------
import { APICore } from '../../mozimo/helpers/api/apiCore';
import * as URL from '../../mozimo/helpers/api/apiEndPoint';

const api = new APICore();

function orderData(params: any): any {
    const { data } = params
    console.log({data},'rderapi')
    return api.get(`${URL.GET_ORDER}${data}`);
}


export {
    orderData
};