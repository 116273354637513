import * as URL from '../../mozimo/helpers/api/apiEndPoint';
import { APICore } from '../../mozimo/helpers/api/apiCore';

const api = new APICore();

function addAddressData(params: any): any {
    const { data } = params;
    return api.create(URL.ADDADDRESS, data);
}
function addEnquaryData(params: any): any {
    const { data } = params;
    return api.create(URL.ENQUARYFORM, data);
}

function createPaymentOrder(params) {
    const { data } = params;
    return api.create(URL.CREATE_PAYMENT_ORDER, data);
}

function verifyPayment(params) {
    const { data } = params;
    return api.create(URL.VERIFY_PAYMENT, data);
}
export { addAddressData, createPaymentOrder, verifyPayment,addEnquaryData }
