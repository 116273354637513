
//------------------------------------C O N S T A N T S----------------------------------------------
export const ProductActionTypes = {
    // GET PRODUCT
    GET_PRODUCT_FIRST: '@@auth/GET_PRODUCT_FIRST',
    GET_PRODUCT_LOADING: '@@auth/GET_PRODUCT_LOADING',
    GET_PRODUCT_SUCCESS: '@@auth/GET_PRODUCT_SUCCESS',
    GET_PRODUCT_ERROR: '@@auth/GET_PRODUCT_ERROR',
    GET_PRODUCT_RESET: '@@auth/GET_PRODUCT_RESET',

    // GET CATEGORY
    GET_CATEGORY_FIRST: '@@auth/GET_CATEGORY_FIRST',
    GET_CATEGORY_LOADING: '@@auth/GET_CATEGORY_LOADING',
    GET_CATEGORY_SUCCESS: '@@auth/GET_CATEGORY_SUCCESS',
    GET_CATEGORY_ERROR: '@@auth/GET_CATEGORY_ERROR',
    GET_CATEGORY_RESET: '@@auth/GET_CATEGORY_RESET',


    // GET PRODUCT DETAIL
    GET_PRODUCT_DETAIL_FIRST: '@@auth/GET_PRODUCT_DETAIL_FIRST',
    GET_PRODUCT_DETAIL_LOADING: '@@auth/GET_PRODUCT_DETAIL_LOADING',
    GET_PRODUCT_DETAIL_SUCCESS: '@@auth/GET_PRODUCT_DETAIL_SUCCESS',
    GET_PRODUCT_DETAIL_ERROR: '@@auth/GET_PRODUCT_DETAIL_ERROR',
    GET_PRODUCT_DETAIL_RESET: '@@auth/GET_PRODUCT_DETAIL_RESET',

    // GET CART DETAIL
    GET_CART_DETAIL_FIRST: '@@auth/GET_CART_DETAIL_FIRST',
    GET_CART_DETAIL_LOADING: '@@auth/GET_CART_DETAIL_LOADING',
    GET_CART_DETAIL_SUCCESS: '@@auth/GET_CART_DETAIL_SUCCESS',
    GET_CART_DETAIL_ERROR: '@@auth/GET_CART_DETAIL_ERROR',
    GET_CART_DETAIL_RESET: '@@auth/GET_CART_DETAIL_RESET',

    // ADD PRODUCT 
    ADDTOCART_FIRST: '@@auth/ADDTOCART_FIRST',
    ADDTOCART_LOADING: '@@auth/ADDTOCART_LOADING',
    ADDTOCART_SUCCESS: '@@auth/ADDTOCART_SUCCESS',
    ADDTOCART_ERROR: '@@auth/ADDTOCART_ERROR',
    ADDTOCART_RESET: '@@auth/ADDTOCART_RESET',

    // BUY PRODUCT 
    PROCEED_TO_BUY_FIRST: '@@auth/PROCEED_TO_BUY_FIRST',
    PROCEED_TO_BUY_LOADING: '@@auth/PROCEED_TO_BUY_LOADING',
    PROCEED_TO_BUY_SUCCESS: '@@auth/PROCEED_TO_BUY_SUCCESS',
    PROCEED_TO_BUY_ERROR: '@@auth/PROCEED_TO_BUY_ERROR',
    PROCEED_TO_BUY_RESET: '@@auth/PROCEED_TO_BUY_RESET',

    // USER ORDERS
    GET_USER_ORDERS_FIRST: '@@auth/GET_USER_ORDERS_FIRST',
    GET_USER_ORDERS_LOADING: '@@auth/GET_USER_ORDERS_LOADING',
    GET_USER_ORDERS_SUCCESS: '@@auth/GET_USER_ORDERS_SUCCESS',
    GET_USER_ORDERS_ERROR: '@@auth/GET_USER_ORDERS_ERROR',
    GET_USER_ORDERS_RESET: '@@auth/GET_USER_ORDERS_RESET',

    // DELETE PRODUCT 
    DELETETOCART_FIRST: '@@auth/DELETETOCART_FIRST',
    DELETETOCART_LOADING: '@@auth/DELETETOCART_LOADING',
    DELETETOCART_SUCCESS: '@@auth/DELETETOCART_SUCCESS',
    DELETETOCART_ERROR: '@@auth/DELETETOCART_ERROR',
    DELETETOCART_RESET: '@@auth/DELETETOCART_RESET',

    STATE_EMPTY_FIRST: '@@auth/STATE_EMPTY_FIRST',
    STATE_EMPTY_SUCCESS: '@@auth/STATE_EMPTY_SUCCESS',
}
