//------------------------------------R E D U C E R S-------------------------------------------------
import { HomeActionTypes } from "./constants"

const GET_HOME_DATA_INITIAL_STATE = {
    homeData: [],
    loading: false
}


const homeDataReducer = (state = GET_HOME_DATA_INITIAL_STATE, action) => {
    switch (action.type) {
        case HomeActionTypes.HOME_DATA_LOADING:
            return {
                homeData: state.homeData,
                loading: true
            }
        case HomeActionTypes.HOME_DATA_SUCCESS:
            return {
                homeData: action.payload,
                loading: false
            }
        case HomeActionTypes.HOME_DATA_ERROR:
            return {
                homeData: action.payload,
                loading: false
            }
        default: return state
    }
}

const brandStoryReducer = (state = GET_HOME_DATA_INITIAL_STATE, action) => {
    switch (action.type) {
        case HomeActionTypes.BRAND_STORY_LOADING:
            return {
                homeData: state.homeData,
                loading: true
            }
        case HomeActionTypes.BRAND_STORY_SUCCESS:
            return {
                homeData: action.payload,
                loading: false
            }
        case HomeActionTypes.BRAND_STORY_ERROR:
            return {
                homeData: action.payload,
                loading: false
            }
        default: return state
    }
}
const logoReducer = (state = GET_HOME_DATA_INITIAL_STATE, action) => {
    switch (action.type) {
        case HomeActionTypes.LOGO_LOADING:
            return {
                homeData: state.homeData,
                loading: true
            }
        case HomeActionTypes.LOGO_SUCCESS:
            return {
                homeData: action.payload,
                loading: false
            }
        case HomeActionTypes.LOGO_ERROR:
            return {
                homeData: action.payload,
                loading: false
            }
        default: return state
    }
}
const instaVideoReducer = (state = GET_HOME_DATA_INITIAL_STATE, action) => {
    switch (action.type) {
        case HomeActionTypes.INSTA_VIDEO_LOADING:
            return {
                homeData: state.homeData,
                loading: true
            }
        case HomeActionTypes.INSTA_VIDEO_SUCCESS:
            return {
                homeData: action.payload,
                loading: false
            }
        case HomeActionTypes.INSTA_VIDEO_ERROR:
            return {
                homeData: action.payload,
                loading: false
            }
        default: return state
    }
}

const instaImageReducer = (state = GET_HOME_DATA_INITIAL_STATE, action) => {
    switch (action.type) {
        case HomeActionTypes.INSTA_IMAGE_LOADING:
            return {
                homeData: state.homeData,
                loading: true
            }
        case HomeActionTypes.INSTA_IMAGE_SUCCESS:
            return {
                homeData: action.payload,
                loading: false
            }
        case HomeActionTypes.INSTA_IMAGE_ERROR:
            return {
                homeData: action.payload,
                loading: false
            }
        default: return state
    }
}

const collectionDataReducer = (state = GET_HOME_DATA_INITIAL_STATE, action) => {
    switch (action.type) {
        case HomeActionTypes.COLLECTION_DATA_LOADING:
            return {
                homeData: state.homeData,
                loading: true
            }
        case HomeActionTypes.COLLECTION_DATA_SUCCESS:
            return {
                homeData: action.payload,
                loading: false
            }
        case HomeActionTypes.COLLECTION_DATA_ERROR:
            return {
                homeData: action.payload,
                loading: false
            }
        default: return state
    }
}

const particularCollectionDataReducer = (state = GET_HOME_DATA_INITIAL_STATE, action) => {
    switch (action.type) {
        case HomeActionTypes.PARTICULAR_COLLECTION_DATA_LOADING:
            return {
                homeData: state.homeData,
                loading: true
            }
        case HomeActionTypes.PARTICULAR_COLLECTION_DATA_SUCCESS:
            return {
                homeData: action.payload,
                loading: false
            }
        case HomeActionTypes.PARTICULAR_COLLECTION_DATA_ERROR:
            return {
                homeData: action.payload,
                loading: false
            }
        default: return state
    }
}
export {
    homeDataReducer, brandStoryReducer, instaVideoReducer, instaImageReducer, collectionDataReducer, particularCollectionDataReducer,logoReducer
}