import React, { useEffect } from 'react';
import Routes from './routes/Routes'
import { v4 as uuidv4 } from 'uuid';


function App() {
  useEffect(() => {
    let deviceId = localStorage.getItem('device_id');

    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem('device_id', deviceId);
    }

    // console.log('Device ID:', deviceId);
  }, []);

  return (
    <>
      <Routes />
    </>
  );
}

export default App;
