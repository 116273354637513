
export const addAddressTypes = {
    ADD_ADDRESS_FIRST: '@@auth/ADD_ADDRESS_FIRST',
    ADD_ADDRESS_SUCCESS: '@@auth/ADD_ADDRESS_SUCCESS',
    ADD_ADDRESS_LOADING: '@@auth/ADD_ADDRESS_LOADING',
    ADD_ADDRESS_ERROR: '@@auth/ADD_ADDRESS_ERROR',

    ADD_ENQUARY_FIRST: '@@auth/ADD_ENQUARY_FIRST',
    ADD_ENQUARY_SUCCESS: '@@auth/ADD_ENQUARY_SUCCESS',
    ADD_ENQUARY_LOADING: '@@auth/ADD_ENQUARY_LOADING',
    ADD_ENQUARY_ERROR: '@@auth/ADD_ENQUARY_ERROR',

    INITIATE_PAYMENT: '@@payment/INITIATE_PAYMENT',
    PAYMENT_SUCCESS: '@@payment/PAYMENT_SUCCESS',
    PAYMENT_LOADING: '@@payment/PAYMENT_LOADING',
    PAYMENT_ERROR: '@@payment/PAYMENT_ERROR',

    VERIFY_PAYMENT_FIRST: '@@payment/VERIFY_PAYMENT_FIRST',
    VERIFY_PAYMENT_LOADING: '@@payment/VERIFY_PAYMENT_LOADING',
    VERIFY_PAYMENT_SUCCESS: '@@payment/VERIFY_PAYMENT_SUCCESS',
    VERIFY_PAYMENT_ERROR: '@@payment/VERIFY_PAYMENT_ERROR',

    STATE_EMPTY_FIRST: '@@auth/STATE_EMPTY_FIRST',
    STATE_EMPTY_SUCCESS: '@@auth/STATE_EMPTY_SUCCESS',
}