import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
// import PrivateRoute from "./PrivateRoute";
import Root from "./Root";
import DefaultLayout from "../mozimo/helpers/Default";

// import Shop, { BarksSection, BarsSection, DrageesSection, MozimoSection, PralinesSection, RocksSection, SpreadsSection, TuillesSection } from './mozimo/pages/SHop';
//routes
const Login = React.lazy(() => import("../mozimo/pages/auth/login"));
const Register = React.lazy(() => import("../mozimo/pages/auth/Register"))

const Home = React.lazy(() => import("../mozimo/pages/Home"));
const About = React.lazy(() => import("../mozimo/pages/About"));
const Gifting = React.lazy(() => import("../mozimo/pages/Gifting"));
const Collections = React.lazy(() => import("../mozimo/pages/Collections"));
const Cart = React.lazy(() => import("../mozimo/pages/Cart"));
const CheckoutPage = React.lazy(() => import("../mozimo/pages/CheckoutPage"));
const Cartaddress = React.lazy(() => import("../mozimo/pages/Cartaddress"));
const Order = React.lazy(() => import("../mozimo/pages/Order"));
const ConfirmOrder = React.lazy(() => import("../mozimo/pages/ConfirmOrder"));
const Profile = React.lazy(() => import("../mozimo/pages/Profile"));
const Location = React.lazy(() => import("../mozimo/pages/Location"));
const Bulk = React.lazy(() => import("../mozimo/pages/Bulk"));
const Otp = React.lazy(() => import("../mozimo/pages/auth/Otp"));
const AccountDetails = React.lazy(() => import("../mozimo/pages/auth/AccountDetails"));
const Shop = React.lazy(() => import("../mozimo/pages/SHop"));
const KnowYourChocolate = React.lazy(() => import("../mozimo/pages/KnowYourChocolate"));
const privacy = React.lazy(() => import("../mozimo/pages/Privacy"));
const ReturnPolicy = React.lazy(() => import("../mozimo/pages/ReturnPolicy"));
const ShippingPolicy = React.lazy(() => import("../mozimo/pages/ShippingPolicy"));
const Faq = React.lazy(() => import("../mozimo/pages/Faq"));
const Termsconditions = React.lazy(() => import("../mozimo/pages/Termsconditions"));



const loading = () => <div className=""></div>;

const LoadComponent = ({ component: Component }) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const AllRoutes = (props) => {
  return useRoutes([
    { path: "/", element: <Root /> },
    {
      // public routes
      path: "/",
      element: <DefaultLayout />,
      children: [
        {
          path: "",
          children: [
            {
              path: "login",
              element: <LoadComponent component={Login} />,
            },
            {
              path: 'register',
              element: <LoadComponent component={Register} />
            },
            {
              path: "home",
              element: <LoadComponent component={Home} />,
            },
            {
              path: "about",
              element: <LoadComponent component={About} />,
            },
            {
              path: "knowyourchocolate",
              element: <LoadComponent component={KnowYourChocolate} />,
            },
            {
              path: "gifting",
              element: <LoadComponent component={Gifting} />,
            },
            {
              path: "collections/:name/:id",
              element: <LoadComponent component={Collections} />,
            },
            {
              path: "product-details",
              element: <LoadComponent component={Cart} />,
            },
            {
              path: "cart",
              element: <LoadComponent component={CheckoutPage} />,
            },
            {
              path: "cart-address",
              element: <LoadComponent component={Cartaddress} />,
            },
            {
              path: "order",
              element: <LoadComponent component={Order} />,
            },
            {
              path: "confirmorder",
              element: <LoadComponent component={ConfirmOrder} />,
            },
            {
              path: "profile",
              element: <LoadComponent component={Profile} />,
            },
            {
              path: "location",
              element: <LoadComponent component={Location} />,
            },
            {
              path: "bulk",
              element: <LoadComponent component={Bulk} />,
            },
            {
              path: "otp",
              element: <LoadComponent component={Otp} />,
            },
            {
              path: "accountDetails",
              element: <LoadComponent component={AccountDetails} />,
            },
            {
              path: "shop",
              element: <LoadComponent component={Shop} />,
            },
            {
              path: "shop/:id",
              element: <LoadComponent component={Shop} />,
            },
            {
              path: "privacy-policy",
              element: <LoadComponent component={privacy} />,
            },
            {
              path: "return-policy",
              element: <LoadComponent component={ReturnPolicy} />,
            },
            {
              path: "shipping-policy",
              element: <LoadComponent component={ShippingPolicy} />,
            },
            {
              path: "faq",
              element: <LoadComponent component={Faq} />,
            },
            {
              path: "terms-and-conditions",
              element: <LoadComponent component={Termsconditions} />,
            },
          ],
        },
      ],
    },
    // {
    //   path: "/",
    //   element: <PrivateRoute roles={""} component={Home} />,
    //   children: [
    //     {
    //       path: "dashboard",
    //       children: [
    //         {
    //           path: "home",
    //           element: <LoadComponent component={Home} />,
    //         },
    //         {
    //           path: "about",
    //           element: <LoadComponent component={About} />,
    //         },

    //       ],
    //     },
    //   ],
    // },
  ]);
};
export { AllRoutes };