
import { addAddressTypes } from './constants';

type AuthAction = { type: string, payload: {} | string };


export const addAddressPayment = (data): AuthAction => ({
    type: addAddressTypes.ADD_ADDRESS_FIRST, data
});
export const addEnquary = (data): AuthAction => ({
    type: addAddressTypes.ADD_ENQUARY_FIRST, data
});

export const createOrder = (data): AuthAction => ({
    type: addAddressTypes.INITIATE_PAYMENT, data
});

export const verifyPayment = (data): AuthAction => ({
    type: addAddressTypes.VERIFY_PAYMENT_FIRST, data
});

export const resetPayment = () => ({
    type: addAddressTypes.STATE_EMPTY_FIRST,
});